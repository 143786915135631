<template>
  <div style="max-width: 800px">
    <div class="card-box">
      <h4>Keyword Search</h4>
      <p>Search through all smses and voice transcripts.</p>
      <CButton
        @click="loadPath('keyword-search/search')"
        color="info"
        class="card-box-button"
      >
        Keyword Search
      </CButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "keyword-search",
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>
